import React from "react";
import { graphql } from "gatsby";
import cx from "classnames";

import theme from "../constants/theme";

import Layout from "../components/layout";
import SEO from "../components/seo";

import { SubHeader, BlogPreviewCard, CTASection } from "../components/blocks";
import { Wrapper } from "../components/core";

export default function blogPageTemplate(props) {
  const siteMetaData = props.data.site.siteMetadata;
  const siteData = props.data.allSanitySiteConfig.edges[0].node;
  const sharedData = props.data.allSanitySharedSections.edges[0].node;
  const pageData = props.data.allSanityBlogPost.edges;

  return (
    <Layout
      locale={props.pageContext.locale}
      siteMetaData={siteMetaData}
      mainNav={siteData.mainNav}
      alternateNav={siteData.alternateNav}>
      <SEO title={`Blog`} />
      <SubHeader title={`Add-Onn Blog`} subTitle={`Learn more about Add-Onn.`} />
      {blogSection()}
      <CTASection
        title={sharedData.callToActionSection?.title?.localized}
        description={sharedData.callToActionSection?.description?.localized}
        callToActionTitle={sharedData.callToActionSection?.callToAction?.linkTitle?.localized}
        callToActionRoute={sharedData.callToActionSection?.callToAction?.route?.slug?.current}
      />
    </Layout>
  );

  function blogSection() {
    return (
      <section className={cx("bg-bg2", theme.spacing.sectionY_md)}>
        <Wrapper>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-20 lg:gap-30">
            {pageData.map(({ node }) => {
              return (
                <BlogPreviewCard
                  key={node.title}
                  to={node.slug.current}
                  title={node.title}
                  author={node.author?.name}
                  category={node.category?.title}
                  date={node.publishDate}
                  coverImageUrl={node.coverImage?.asset?.fluid?.src}
                />
              );
            })}
          </div>
        </Wrapper>
      </section>
    );
  }
}

import * as _ from "./fragments";

export const query = graphql`
  query($locale: String) {
    # Site Meta Data
    site {
      ...SiteMetaData
    }
    # Site
    allSanitySiteConfig {
      edges {
        node {
          ...MainNav
          ...AlternateNav
        }
      }
    }
    # Shared
    allSanitySharedSections {
      edges {
        node {
          ...CallToActionSection
        }
      }
    }
    # Page
    allSanityBlogPost(
      sort: { fields: publishDate, order: DESC }
      filter: { isPublish: { eq: true } }
    ) {
      edges {
        node {
          title
          slug {
            current
          }
          publishDate(formatString: "LL", locale: $locale)
          coverImage {
            ...Image
          }
          author {
            name
            profileImage {
              ...Image
            }
          }
          category {
            title
          }
        }
      }
    }
  }
`;
